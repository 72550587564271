export const colors = {
  // General
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayColor: "#7B7B80",
  lightGrayColor: "#EFEFF3",
  purpleColor: "#C085F2",
  greenColor: "#54C9B7",
  blueColor: "#4C8FFD",

  //Point
  activeRed: "#FF0914",
  activeBlue: "#264CD2",
  activeOrange: "#FB8C01",
  activeGreen: "#007B45",
  activeChecked: "#0078FF",
  activeGraphBgColor: "#0091F7",

  //Font
  fontGrayColor: "#7B7B80",
  fontLightGrayColor: "#AAAAAA",
  fontInputGuideGray: "#B3B3B3",
  fontMoreGrayColor: "#D5D5D5",

  // Background
  darkShadowColor: "#2D2E2F",
  shadowColor: "rgb(216, 216, 216)",
  emptyBackgroundColor: "#F4F6F9",

  // Border
  borderLightGray: "#CACBD4",
  lightGrayBgColor: "#F3F3F3",
  radioLightGray: "#D5D5D5",
  radioSkyBlue: "#15A9FF",
  progressBarGray: "#4B4B4B",
};
