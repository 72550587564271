import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing: border-box;
    }
    body {
        font-size: 18px;
        background-color : ${colors.blackColor};
        font-family: "Pretendard-Regular";
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
        body::-webkit-scrollbar {display: none;}
    }

    a {
      text-decoration: none;
      color: ${colors.whiteColor}; 
    }

    input:focus{
      outline: none;
    }
    button {
      border: none;
      background-color: inherit;
    }
    button,
    select {
      cursor: pointer;
      -webkit-appearance: none; /* 화살표 없애기 for chrome*/
      -moz-appearance: none; /* 화살표 없애기 for firefox*/
      appearance: none; /* 화살표 없애기 공통*/
    }
    .list-item {
      display: flex;
      justify-content: space-between;
    }
    ul {
      width: 375px;
    }
    li {
      color: ${colors.whiteColor}; 
      padding: 15px;
      font-size: 15px;
      border-bottom: 1px dashed;
    }
    span {
      color: ${colors.whiteColor}; 
    }
`;
