import styled from "styled-components";
import { colors } from "../../styles/colors";

export const MenuBackground = styled.div`
  padding-top: 80px;
  top: 0;
  left: calc((100vw - 375px )/ 2);
  width: 375px;
  height: calc(100vh - 212px); // 헤더, 푸터 높이 제외
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const MenuButton = styled.button`
  display: flex;
  color: ${colors.whiteColor};
  padding: 12px 15px;
  justify-content: flex-start;
  width: 100%;
  font-size: 24px;
  font-weight: "Pretendard-Regular";
`;