import React from "react";
import { GuideHeader, GuideContent, GuideContainer } from "./guide.styles";

const SecondGuide = () => {

    return (
        <GuideContainer>
        <GuideHeader>
            가이드 Second
        </GuideHeader>
        <GuideContent>
            준비중입니다.
        </GuideContent>
        </GuideContainer>
    );
}

export default SecondGuide;