import styled from "styled-components";
import { colors } from "../../styles/colors";

export const TypeWrapper = styled.div`
    width: 345px;
    margin: 30px auto;
`

export const TypeTitle = styled.h1`
    color: ${colors.whiteColor};
    display: block;
    font-family: "Pretendard-SemiBold";
    margin: 12px 0;
`

export const TypeContent = styled.span`
    color: ${colors.whiteColor};
    display: block;
    line-height: 28px;
`