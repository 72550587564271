import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/colors";
import { EmailGuide, HelpContainer, HelpTitle, InputContent, InputEmail, InputTitle, SubmitButton } from "./help.styles";

const Help = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isEmail, setIsEmail] = useState(true);
    const [emailMessage, setEmailMessage] = useState('@를 포함한 전체 이메일을 입력해 주세요.')

    const onChangeEmail = (e) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail);
        const emailRegExp =
            /^[A-Za-z0-9_]+[A-Za-z0-9][@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{2,3}$/;
            
        if (currentEmail === "") {
            setEmailMessage('@를 포함한 전체 이메일을 입력해 주세요.');
            setIsEmail(true);
        }
        else if (!emailRegExp.test(currentEmail)) {
            setEmailMessage('이메일의 형식이 올바르지 않습니다.');
            setIsEmail(false);
        } else {
            setEmailMessage('올바른 이메일 형식입니다.');
            setIsEmail(true);
        }
    }

    const handleSubmit = () => {
        if (email === ''){
            alert('이메일을 입력해 주세요.');
        } else{
            alert('전송이 완료되었습니다. 빠른 시간 안에 답변드리겠습니다.');
            navigate('/')
        }

    }

    return(
        <>
            <div>
                <HelpContainer>
                    <HelpTitle>UBTI 문의하기</HelpTitle>
                    <InputEmail placeholder="이메일 입력" type="email" value={email} required onChange={onChangeEmail}></InputEmail>
                    <EmailGuide style={{color: isEmail ? `${colors.fontInputGuideGray}` : 'red'}}>{emailMessage}</EmailGuide>
                    <InputTitle placeholder="제목 입력" type="text" maxlength="80" required></InputTitle>
                    <InputContent placeholder="내용 입력" type="text" maxlength="500" required></InputContent>
                    <SubmitButton onClick={handleSubmit}>전송하기</SubmitButton>
                </HelpContainer>
            </div>
        </>
    );
}
export default Help;