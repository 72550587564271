import styled from "styled-components";
import { colors } from "../../styles/colors";


export const ResultContainer = styled.div`
    width: 345px;
    margin: 20px auto 0 auto;
`

export const TypeName = styled.span`
    color: ${colors.whiteColor};
    font-size: 18px;
    font-family: "Pretendard-Regular";
`

export const UBTI = styled.h1`
    color: ${colors.whiteColor};
    font-size: 192px;
    font-family: "Pretendard-SemiBold";
`

export const TypeDetail = styled.span`
    color: ${colors.whiteColor};
    font-size: 18px;
    line-height: 31px;
    font-family: "Pretendard-SemiBold";
`

export const TypeImg = styled.img`
    height: 274px;
    margin: 80px auto;
    display: block;
`

export const TwoTypes = styled.span`
    color: ${colors.whiteColor};
    font-size: 15px;
    display: block;
    margin: 30px auto 15px auto;
    text-align: center;
`

export const RatioWrapper = styled.div`
    margin-bottom: 80px;
`

export const RatioBar = styled.div`
    background-color: ${colors.progressBarGray};
    width: 266px;
    height: 9px;
    border-radius: 5px;
    margin: 0 auto;
`

export const Ratio = styled.div`
    background-color: ${colors.whiteColor};
    height: 100%;
    border-radius: 5px;
`

export const SeparateTypeDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px auto 12px auto;
`

export const SeparateTypeTitle = styled.span`
    color: ${colors.whiteColor};
    font-size: 18px;
    font-family: "Pretendard-SemiBold";
`

export const SeparateTypePer = styled.span`
    color: ${colors.whiteColor};

`

export const SeparateTypeDetail = styled.span`
    color: ${colors.whiteColor};
    font-size: 15px;
    display: block;
    line-height: 18px;
`

export const More = styled.div`
    color: ${colors.fontMoreGrayColor};
    font-size: 15px;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    border-bottom: 1px dashed ${colors.whiteColor};
`

export const SendResult = styled.div`
    width: 345px;
    margin: 0 auto;
`

export const SendTitle = styled.h2`
    color: ${colors.whiteColor};
    margin: 20px 0;
    font-size: 18px;
    font-family: "Pretendard-SemiBold";
`

export const PhoneNumber = styled.input`
    border-color: ${colors.whiteColor};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    font-size: 15px;
    color: ${colors.whiteColor};
    width: 100%;
    height: 50px;
    background: none;
    padding: 12px;
    box-shadow: none;
    margin-bottom: 5px;
`

export const PhoneNumberGuide = styled.span`
  color: ${colors.fontInputGuideGray};
  font-size: 12px;
  padding: 0px;
  margin-left: 5px;
`

export const SendButton = styled.button`
    font-size: 15px;
    background-color: ${colors.whiteColor};
    color: ${colors.blackColor};
    height: 50px;
    width: 100%;
    margin: 40px 0px;
    border-radius: 4px;
`