import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Wrapper = styled.div`
  max-height: 1000px;
  display: flex;
  width: 375px;
  /* border: 1px solid yellow; */
  height: 100vh;
  /* justify-content: space-between; */
  flex-direction: column;
  margin: 0 auto;
`;

export const MainContainer = styled.div`
  /* display: flex;
  align-items: flex-start; */
  width: 100%;
  padding: 0px 15px;
  padding-top: 20px;
  height: 30vh;
`;

export const MainLogo = styled.img`
  width: 100%;
`;

export const GuideContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 33vh;
  flex-direction: column;
`;

export const GuideLine = styled.div`
  display: flex;
  padding: 0px 15px;
  color: ${colors.whiteColor};
  height: 52px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
  @media screen and (min-width: 720px) {
    font-size: 24px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  height: 33vh;
  color: ${colors.whiteColor};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
  width: 100%;
  padding: 0px 15px;
  margin-top: 32px;
  flex: 1;
`;

export const BottomButton = styled.div`
  display: flex;
  width: calc(50% - 15px);
  height: calc(50% - 15px);
  border: 1px dashed ${colors.whiteColor};
  min-height: 162px;
  border-radius: 4px;
  padding: 30px;
  color: ${colors.whiteColor};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const BottomIcon = styled.img`
  width: 72px;
  height: 72px;
  margin-bottom: 12px;
`;
