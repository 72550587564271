import React, { useEffect, useState } from "react";
import {
  BottomButton,
  BottomContainer,
  BottomIcon,
  GuideContainer,
  GuideLine,
  MainContainer,
  MainLogo,
  Wrapper,
  ButtonWrapper,
} from "./home.styles";
import MAINLOGO from "../../assets/ubti_logo.svg";
import MANICON from "../../assets/man.svg";
import WOMANICON from "../../assets/woman.svg";
import { useNavigate } from "react-router-dom";

const GuideData = [
  {
    id: 1,
    label: "가이드 First",
    type: "FIRST",
  },
  {
    id: 2,
    label: "가이드 Second",
    type: "SECOND",
  },
  {
    id: 3,
    label: "가이드 Third",
    type: "THIRD",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [routeInfo, setRouteInfo] = useState({ type: null, path: null });

  useEffect(() => {
    if (routeInfo.type !== null && routeInfo.path !== null) {
      navigate(routeInfo.path, { state: { type: routeInfo.type } });
    }
  }, [routeInfo, navigate]);

  const handleRoute = (typeValue, path) => {
    setRouteInfo({ type: typeValue, path: path });
  };

  return (
    <Wrapper>
      <MainContainer>
        <MainLogo src={MAINLOGO} />
      </MainContainer>
      <GuideContainer>
        {GuideData.map((item) => (
          <GuideLine
            key={"Explain" + item.id}
            onClick={() => handleRoute(item.type, `/guide${item.id}`)}
          >
            {item.label}
          </GuideLine>
        ))}
      </GuideContainer>
      <BottomContainer>
        검사 시작 전, 성별을 선택해주세요.
        <ButtonWrapper>
          <BottomButton>
            <BottomIcon
              src={MANICON}
              alt="남자"
              onClick={() => handleRoute("MAN", "/test")}
            />
            남성
          </BottomButton>
          <BottomButton>
            <BottomIcon
              src={WOMANICON}
              alt="여자"
              onClick={() => handleRoute("WOMAN", "/test")}
            />
            여성
          </BottomButton>
        </ButtonWrapper>
      </BottomContainer>
    </Wrapper>
  );
};

export default Home;
