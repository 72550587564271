import styled from "styled-components";
import { colors } from "../../styles/colors";

export const HelpContainer = styled.div`
  width: 345px;
  margin: 0 auto;
`
export const HelpTitle = styled.div`
  color: ${colors.whiteColor};
  margin: 32px 0;
`;

export const InputEmail = styled.input`
  border-color: ${colors.whiteColor};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: 15px;
  color: ${colors.whiteColor};
  width: 100%;
  height: 50px;
  background: none;
  padding: 12px;
  box-shadow: none;
  margin-bottom: 5px;
`;

export const EmailGuide = styled.span`
  color: ${colors.fontInputGuideGray};
  font-size: 12px;
  padding: 0px;
  margin-left: 5px;
`
export const InputTitle = styled.input`
  border-color: ${colors.whiteColor};
  border-style: solid;
  border-width: 1px;
  background: none;
  color: ${colors.whiteColor};
  border-radius: 5px;
  width: 100%;
  margin: 20px 0px;
  padding: 12px;
  height: 50px;
`;
export const InputContent = styled.textarea`
  border: ${colors.whiteColor};
  border-style: solid;
  border-width: 1px;
  background: none;
  color: ${colors.whiteColor};
  border-radius: 5px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  height: 120px;
  resize: none;
`;

export const SubmitButton = styled.button`
  background-color: ${colors.whiteColor};
  color: ${colors.blackColor};
  height: 50px;
  width: 100%;
  margin: 20px 0px;
  border-radius: 4px;
`