import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/GlobalStyles";
import { GuideLi, GuideUl, ListWrapper } from "./types.styles";

const Types = () => {
    const navigate = useNavigate();
    const goToPage = (path) => {
        navigate(path); // Navigate after modal close
      };

    const allTypes = [    
        {name:"목표 지향적인 유형", ubti:"ABCD"},
        {name:"책임감 있는 유형", ubti:"ABCD"},
        {name:"자발적으로 운동하는 유형", ubti:"ABCD"},
        {name:"도전적으로 운동하는 유형", ubti:"ABCD"},
        {name:"강인하게 행동하는 유형", ubti:"ABCD"},
        {name:"자발적으로 운동하는 유형", ubti:"ABCD"},
        {name:"도전적으로 운동하는 유형", ubti:"ABCD"},
        {name:"강인하게 행동하는 유형", ubti:"ABCD"},
        {name:"자발적으로 운동하는 유형", ubti:"ABCD"},
        {name:"도전적으로 운동하는 유형", ubti:"ABCD"},
        {name:"강인하게 행동하는 유형", ubti:"ABCD"},
        {name:"자발적으로 운동하는 유형", ubti:"ABCD"},
        {name:"도전적으로 운동하는 유형", ubti:"ABCD"},
        {name:"강인하게 행동하는 유형", ubti:"ABCD"},
        {name:"도전적으로 운동하는 유형", ubti:"ABCD"},
        {name:"강인하게 행동하는 유형", ubti:"ABCD"},
    ];

    return(
        <>
        <ListWrapper>
            <GuideUl>
                {/* map 반복문 */}
                {allTypes.map((type, index) => (   
                        <GuideLi className="list-item" key={index} onClick={() => goToPage('/typeDetail')}>
                            <span>{type.name}</span>
                            <span>{type.ubti}</span>
                        </GuideLi>
                    )
                )}
            </GuideUl>
        </ListWrapper>
        </>
    );
};

export default Types;