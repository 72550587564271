import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Wrapper = styled.header`
  display: flex;
  width: 375px;
  height: 52px;
  align-items: center;
  padding: 15px;
  margin: 0 auto;
`;
export const IconBox = styled.div`
  cursor: pointer;
  margin: 15px 0 0 5px;

`;

export const HomeWrapper = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
`
export const LanguageButton = styled.span`
  display: inline;
  color: ${colors.whiteColor};
  font-size: 22px;
`
export const HamburgerIcon = styled.img`
  display: inline;
  width: 22px;
  height: 22px;
`;

export const BackButton = styled.button `
  color: ${colors.whiteColor};
`;
