import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import styled from "styled-components";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import FirstGuide from "./pages/Guide/firstGuide";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Result from "./pages/Result";
import Test from "./pages/Test";
import TypeDetail from "./pages/TypeDetail";
import Types from "./pages/Types";
import SecondGuide from "./pages/Guide/secondGuide";
import ThirdGuide from "./pages/Guide/thirdGuide";

const Container = styled.div`
  height: auto;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  min-height: calc(100% - 227px);
  margin: auto;
`;

const MainRoutes = () => (
   // Routes는 여러 route를 감싸서 그 중 규칙이 일치하는 라우트 하나만을 렌더링 시켜 줌.
  <Routes> 
    {/* Route는 path에 경로, element에 컴포넌트를 넣어 준다. 여러 라우팅을 매칭하고 싶으면 URL 뒤에 *를 사용 */}
    <Route path='/*' element={<Home />} />
    <Route path='/types' element={<Types />} />
    <Route path='/help' element={<Help />} />
    <Route path='/test' element={<Test />} />
    <Route path='/result' element={<Result />} />
    <Route path='/guide1' element={<FirstGuide />} />
    <Route path='/guide2' element={<SecondGuide />} />
    <Route path='/guide3' element={<ThirdGuide />} />
    <Route path='/typeDetail' element={<TypeDetail />} />
    <Route path='/menu' element={<Menu />} />
  </Routes>
);

const AppRouter = () => {

  return (
    <Router>
      <Container>
      <Header />
      <ContentWrapper>
        <MainRoutes />
      </ContentWrapper>
      <Footer />
      </Container>
    </Router>
  );
};

export default AppRouter;
