import React, { useState } from "react";
import TYPEIMAGE from '../../assets/result_deer_image.png';
import "../../styles/GlobalStyles";
import {
    More,
    PhoneNumber, PhoneNumberGuide,
    Ratio,
    RatioBar,
    RatioWrapper,
    ResultContainer,
    SendButton,
    SendResult, SendTitle,
    SeparateTypeDetail,
    SeparateTypeDiv,
    SeparateTypePer,
    SeparateTypeTitle,
    TwoTypes,
    TypeDetail, TypeImg,
    TypeName, UBTI
} from "./result.styles";

const Result = () => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const phoneAutoHyphen = (value) => {
        return value
            .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/(-{1,2})$/g, "");
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = phoneAutoHyphen(e.target.value);
        setPhoneNumber(formattedPhoneNumber);
    };

    const handleSend = () => {
        if (phoneNumber === ''){
            alert("휴대폰 번호를 입력해 주세요.");
        } else {
            alert("카카오톡으로 검사 결과를 전송했습니다.");
        }
    };

    return (
        <>
            <ResultContainer>
                <TypeName>나의 운동 유형: 라인이 이쁜 사슴형</TypeName>
                <UBTI>IIHR</UBTI>
                <TypeDetail>독립적이며 자신만의 구체적인 목표를 가지고 있으며, 규칙적이고 효과적인 운동을 선호합니다.</TypeDetail>
                <TypeImg src={TYPEIMAGE}></TypeImg>

                <RatioWrapper>
                <TwoTypes>개인운동 (70%) / 단체운동 (30%)</TwoTypes>
                <RatioBar><Ratio style={{width:'70%'}}></Ratio></RatioBar>
                <TwoTypes>내적 동기 (52%) / 외적 동기 (48%)</TwoTypes>
                <RatioBar><Ratio style={{width:'52%'}}></Ratio></RatioBar>
                <TwoTypes>고중량 (80%) / 저중량 (20%)</TwoTypes>
                <RatioBar><Ratio style={{width:'80%'}}></Ratio></RatioBar>
                <TwoTypes>규칙적 (90%) / 자유 (10%)</TwoTypes>
                <RatioBar><Ratio style={{width:'90%'}}></Ratio></RatioBar>
                </RatioWrapper>

                <SeparateTypeDiv>
                    <SeparateTypeTitle>I형 (개인운동)</SeparateTypeTitle>
                    <SeparateTypePer>70%</SeparateTypePer>
                </SeparateTypeDiv>
                <SeparateTypeDetail>개인의 목표의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.</SeparateTypeDetail>

                <SeparateTypeDiv>
                    <SeparateTypeTitle>I형 (내적동기)</SeparateTypeTitle>
                    <SeparateTypePer>52%</SeparateTypePer>
                </SeparateTypeDiv>
                <SeparateTypeDetail>개인의 목표의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.</SeparateTypeDetail>
                
                <SeparateTypeDiv>
                    <SeparateTypeTitle>H형 (고중량)</SeparateTypeTitle>
                    <SeparateTypePer>80%</SeparateTypePer>
                </SeparateTypeDiv>
                <SeparateTypeDetail>개인의 목표의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.</SeparateTypeDetail>
                                
                <SeparateTypeDiv>
                    <SeparateTypeTitle>R형 (규칙적)</SeparateTypeTitle>
                    <SeparateTypePer>80%</SeparateTypePer>
                </SeparateTypeDiv>
                <SeparateTypeDetail>개인의 목표의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.</SeparateTypeDetail>
                <More>나의 운동 성향 더보기+</More>
            </ResultContainer>

            <SendResult>
                <SendTitle>카카오톡 결과 전송하기</SendTitle>
                <PhoneNumber placeholder="휴대폰번호 (-)생략" type="text" name="phoneNumber" value={phoneNumber} onChange={handlePhoneChange} required></PhoneNumber>
                <PhoneNumberGuide>카카오톡 전송은 대한민국만 지원됩니다.</PhoneNumberGuide>
                <SendButton onClick={handleSend}>전송하기</SendButton>
            </SendResult>
        </>
    );
}

export default Result;