import styled from "styled-components";
import { colors } from "../../styles/colors";

export const GuideContainer = styled.div`
    height: calc(100vh - 232px);
`
export const GuideHeader = styled.h1`
    color: ${colors.whiteColor};
    font-family: "Pretendard-SemiBold";
    width: 345px;
    margin: 20px auto;
`

export const GuideContent = styled.span`
    color: ${colors.whiteColor};
    display: block;
    width: 345px;
    margin: 0 auto;
    line-height: 21px;
`