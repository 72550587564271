import styled from "styled-components";

export const ListWrapper = styled.div`
    width: 375px;
    margin: 0 auto;
`

export const GuideUl = styled.ul`
    margin: 15px auto;
`

export const GuideLi = styled.li`
    cursor: pointer;
`