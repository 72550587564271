import styled from "styled-components";
import { colors } from "../../styles/colors";

export const TestContainer = styled.div`
  width: 345px;
  margin: 0 auto;
`
export const TestForm = styled.div`
  color: ${colors.whiteColor};
  width: 100%;
  margin: 15px;
  display: block;
  `;

export const QuestionSpan = styled.span`
  color: ${colors.whiteColor};
  text-align: center;
  font-size: 18px;
  font-family: "Pretendard-SemiBold";
  line-height: 22px;
`

export const QuestionDiv = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${colors.whiteColor};;
  text-align: center;
  margin-top: 35px;
`

export const ContentDiv = styled.div`
  margin: 20px 0px;
`

export const LabelWrapper = styled.div`
  width: 345px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const TestLabel = styled.label`
  margin: 0 0;
  padding: 0 0;
  display: inline-block;
  width: 36px;
  height: 36px;
`

export const RadioButtonBig = styled.input`
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background-color: ${colors.radioLightGray};
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin: 0;

  &:checked {
    background-color: ${colors.radioSkyBlue};
    border: 6px solid ${colors.whiteColor};
    box-shadow: 0 0 0 2px ${colors.radioSkyBlue};
  }
`

export const RadioButtonMid = styled.input`
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background-color: ${colors.radioLightGray};
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-top: 4px;

  &:checked {
    background-color: ${colors.radioSkyBlue};
    border: 5px solid ${colors.whiteColor};
    box-shadow: 0 0 0 2px ${colors.radioSkyBlue};
  }
`

export const RadioButtonSmall = styled.input`
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background-color: ${colors.radioLightGray};
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-top: 7px;

  &:checked {
    background-color: ${colors.radioSkyBlue};
    border: 4px solid ${colors.whiteColor};
    box-shadow: 0 0 0 2px ${colors.radioSkyBlue};
  }
`

export const NextButton = styled.button`
  margin: 60px auto 60px auto;
  background-color: ${colors.whiteColor};
  height: 52px;
  width: 100%;
  border-radius: 4px;
  font-size: 18px;
`

export const AgreeTextDiv = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  color: ${colors.whiteColor};
`