import React from 'react';
import { useLocation } from 'react-router-dom';
import { Div, Wrapper } from './footer.styles';

const Footer = () => {

  const { pathname } = useLocation();

  if (pathname === '/') return null;
  if (pathname === '/admin') return null;

   return (
    <Div>
      <Wrapper>
        Copyright ⓒ wookmini All right reserved.
      </Wrapper>
    </Div>
   ); 
}

export default Footer;

