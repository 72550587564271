import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ProgressWrapper = styled.div`
    width: 375px;
    display: flex;
    justify-content: space-evenly;
    margin: 15px auto;
`

export const IconBox = styled.div`
    cursor: pointer;
    margin-top: 13px;
`;

export const ProgressBar = styled.div`
    background-color: ${colors.progressBarGray};
    width: 220px;
    height: 9px;
    border-radius: 5px;
    display: flex;
    margin-top: 21px;
`

export const ProgressNow = styled.div`
    background-color: ${colors.whiteColor};
    height: 100%;
    border-radius: 5px;
`

export const ProgressPer = styled.span`
    color: ${colors.whiteColor};
    font-size: 18px;
    margin-top: 18px;
`