import React from "react";
import { GuideHeader, GuideContent, GuideContainer } from "./guide.styles";

const FirstGuide = () => {

    return (
        <GuideContainer>
        <GuideHeader>
            가이드 First
        </GuideHeader>
        <GuideContent>
            대법원장과 대법관이 아닌 법관은 대법관 회의의 동의를 얻어 대법원장이 임명한다. 국회는 정부의 동의 없이 정부가 제출한 지출 예상 각 항의 금액을 증가하거나 새 비목을 설치할 수 없다.<br/><br/>
            전직대통령의 신분과 예우에 관하여는 법률로 정한다. 지방의회의 조직, 권한, 의원선거와 지방자치단체의 장의 선임방법 기타 지방자치단체의 조직과 운영에 관한 사항은 법률로 정한다.
        </GuideContent>
        </GuideContainer>
    );
}

export default FirstGuide;