import { styled } from 'styled-components';
import { colors } from '../../../styles/colors';

export const Div = styled.footer`
`
export const Wrapper = styled.div`
    width: 100%;
    height: 160px;
    padding: 100px 0 40px 0;
    color: ${colors.grayColor};
    font-size: 15px;
    text-align: center;
`;