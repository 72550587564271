import { useNavigate } from "react-router-dom";
import {
  MenuBackground,
  MenuButton,
  MenuContent,
} from "./menu.styles";

const Menu = () => {
    const navigate = useNavigate();

    const goToPage = (path) => {
      navigate(path); 
    };

    return (
        <>
          <MenuBackground>
            <MenuContent onClick={(e) => e.stopPropagation()}>
              <MenuButton onClick={() => goToPage("/types")}>운동 유형 종류</MenuButton>
              <MenuButton onClick={() => goToPage("/help")}>문의</MenuButton>
            </MenuContent>
          </MenuBackground>
        </>
    );
}

export default Menu;