import React, { useMemo } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { TbBrightnessFilled } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import HAMBURGER from "../../../assets/hamburger.svg";
import { colors } from "../../../styles/colors";
import {
  HamburgerIcon,
  HomeWrapper,
  IconBox,
  LanguageButton,
  Wrapper
} from "./header.styles";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isGoBack = useMemo(() => {
    switch(pathname) {
      case '/guide1':
      case '/guide2':
      case '/guide3':
      case '/help':
      case '/result':
      case '/types':
      case '/typeDetail':
      case '/menu':
        return true;
      default:
        return false;
    }
  }, [pathname]);

  const isHamburger = useMemo(() => {
    switch(pathname) {
      case '/':
        return true;
      default:
        return false;
    }
  }, [pathname]);

  const goToPage = (path) => {
    switch(pathname) {
      case '/typeDetail':
        navigate('/types');
        break;
      case '/types':
        navigate('/menu');
        break;
      default:
        navigate(path);
        break;
    }
  };

  return (
    <>
      <Wrapper>
        <IconBox>
          {isGoBack && (
            <IoMdArrowBack style={{display:"flex"}} color={colors.whiteColor} fontSize={24} onClick={() => goToPage("/")}/>
          )}
          {isHamburger && (
            <>
            <HomeWrapper>
              <LanguageButton>KOR</LanguageButton>
              <TbBrightnessFilled style={{display:"inline"}} color={colors.whiteColor} fontSize={24} />
              <HamburgerIcon src={HAMBURGER} alt="메뉴" onClick={() => goToPage("/menu")} />
            </HomeWrapper>
            </>
          )}
        </IconBox> 
      </Wrapper>
    </>
  );
};

export default Header;
