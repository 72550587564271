import React, { useEffect, useState } from "react";
import { AiOutlineSwapRight } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/colors";
import { IconBox, ProgressBar, ProgressNow, ProgressPer, ProgressWrapper } from './ProgressBar';
import { AgreeTextDiv, ContentDiv, LabelWrapper, NextButton, QuestionDiv, QuestionSpan, RadioButtonBig, RadioButtonMid, RadioButtonSmall, TestContainer, TestLabel } from "./test.styles";

const questions = [
    "1번째 질문",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "9번째 질문",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "17번째 질문",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "25번째 질문",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "33번째 질문",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다.",
    "나는 개인 운동보다 단체 운동을 더 선호한다.",
    "나는 계획을 세우기보다는 즉흥적으로 행동하는 편이다."
];

const ITEMS_PER_PAGE = 8;

const Test = () => {
    const navigate = useNavigate();
    const [routeInfo, setRouteInfo] = useState({ type: null, path: null });
    const [page, setPage] = useState(0);  
    const [answers, setAnswers] = useState({}); 

    const startIndex = page * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentQuestions = questions.slice(startIndex, endIndex); // slice는 두번째 매개변수 값은 포함하지 않는다.

    const progress = ((page + 1) / Math.ceil(questions.length / ITEMS_PER_PAGE)) * 100;

    useEffect(() => {
        if (routeInfo.type !== null && routeInfo.path !== null) {
          navigate(routeInfo.path, { state: { type: routeInfo.type } });
        }
      }, [routeInfo, navigate]);

    const goToPage = (path) => {
        navigate(path);
    };

    const handleNext = () => {
        const allAnswered = currentQuestions.every((_, index) => answers[`question-${page}-${index}`] !== undefined);
        console.log(allAnswered);
        if (!allAnswered){
            alert("모든 문항에 답변해 주세요.");
            return;
        }
        if (page < Math.ceil(questions.length / ITEMS_PER_PAGE) - 1) {  // ceil은 반올림
            setPage(page + 1);
            // setAnswers({});
            window.scrollTo(0, 0);  // 다음 버튼 누르면 스크롤 맨 위로 이동
        } else {
            goToPage("/result");
        }
    }

    const handleRadioChange = (questionIndex, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [`question-${page}-${questionIndex}`]: value,
        }));
    };


    return (
        <>
        <ProgressWrapper>
            <IconBox onClick={() => goToPage("/")}>
                <IoMdArrowBack style={{display:'flex'}} color={colors.whiteColor} fontSize={24} ></IoMdArrowBack>
            </IconBox>
            <ProgressBar>
                <ProgressNow style={{width: `${progress}%`}}></ProgressNow>
            </ProgressBar>
            <ProgressPer>{progress}%</ProgressPer>
        </ProgressWrapper>
        <TestContainer>
            {currentQuestions.map((question, index) => (
                    <QuestionDiv key={`question-${page}-${index}`}>
                        <ContentDiv>
                            <QuestionSpan>
                                {question}
                            </QuestionSpan>
                        </ContentDiv>
                        <LabelWrapper>
                        <TestLabel>
                            <RadioButtonBig 
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="1" 
                                value="1"   
                                checked={answers[`question-${page}-${index}`] === "1"}
                                onChange={() => handleRadioChange(index, "1")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonMid 
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="2" 
                                value="2" 
                                checked={answers[`question-${page}-${index}`] === "2"}
                                onChange={() => handleRadioChange(index, "2")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonSmall
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="3" 
                                value="3"
                                checked={answers[`question-${page}-${index}`] === "3"}
                                onChange={() => handleRadioChange(index, "3")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonSmall
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="4"
                                value="4"
                                checked={answers[`question-${page}-${index}`] === "4"}
                                onChange={() => handleRadioChange(index, "4")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonSmall 
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="5" 
                                value="5" 
                                checked={answers[`question-${page}-${index}`] === "5"}
                                onChange={() => handleRadioChange(index, "5")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonMid 
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="6" 
                                value="6" 
                                checked={answers[`question-${page}-${index}`] === "6"}
                                onChange={() => handleRadioChange(index, "6")}/>
                        </TestLabel>
                        <TestLabel>
                            <RadioButtonBig 
                                type="radio" 
                                name={`question-${page}-${index}`} 
                                id="7" 
                                value="7" 
                                checked={answers[`question-${page}-${index}`] === "7"}
                                onChange={() => handleRadioChange(index, "7")}/>
                        </TestLabel>
                        </LabelWrapper>
                        <AgreeTextDiv>
                            <span style={{marginLeft:"0px"}}>비동의</span>
                            <span style={{marginRight:"2px"}}>동의</span>
                        </AgreeTextDiv>
                    </QuestionDiv>
            ))}
        <NextButton
            onClick={handleNext}>다음 <AiOutlineSwapRight/>
        </NextButton>
        </TestContainer>
        </>
    );
};

export default Test;