import React from "react";
import { TypeTitle, TypeContent, TypeWrapper } from "./typeDetail.styles";

const TypeDetail = () => {
    return (
        <>
        <TypeWrapper>
            <TypeTitle>
                I형 (개인 운동)
            </TypeTitle>
            <TypeContent>
                개인의 목표 의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.
            </TypeContent>
        </TypeWrapper>

        <TypeWrapper>
            <TypeTitle>
                I형 (내적 동기)
            </TypeTitle>
            <TypeContent>
                개인의 목표 의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.
            </TypeContent>
        </TypeWrapper>

        <TypeWrapper>
            <TypeTitle>
                H형 (고중량)
            </TypeTitle>
            <TypeContent>
                개인의 목표 의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.
            </TypeContent>
        </TypeWrapper>

        <TypeWrapper>
            <TypeTitle>
                R형 (규칙적)
            </TypeTitle>
            <TypeContent>
                개인의 목표 의식이 뚜렷하여 단체 운동보다 개인 운동을 더 선호하는 타입입니다. 운동에 진심이며 끌리면 하루에 두 번도 운동을 진행합니다.
            </TypeContent>
        </TypeWrapper>
        </>
    );
};

export default TypeDetail;